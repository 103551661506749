/* Webfonts */

@font-face {
    font-family: 'Mark';
    src: url('../assets/fonts/Mark-Regular.eot');
    src: local('Mark-Regular'),
        url('../assets/fonts/Mark-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Mark-Regular.woff2') format('woff2'),
        url('../assets/fonts/Mark-Regular.woff') format('woff'),
        url('../assets/fonts/Mark-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mark';
    src: url('../assets/fonts/Mark-Medium.eot');
    src: local('Mark-Medium'),
        url('../assets/fonts/Mark-Medium.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Mark-Medium.woff2') format('woff2'),
        url('../assets/fonts/Mark-Medium.woff') format('woff'),
        url('../assets/fonts/Mark-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Mark';
    src: url('../assets/fonts/Mark-Bold.eot');
    src: local('Mark-Bold'),
        url('../assets/fonts/Mark-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Mark-Bold.woff2') format('woff2'),
        url('../assets/fonts/Mark-Bold.woff') format('woff'),
        url('../assets/fonts/Mark-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Mark';
    src: url('../assets/fonts/Mark-Heavy.eot');
    src: local('Mark-Heavy'),
        url('../assets/fonts/Mark-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Mark-Heavy.woff2') format('woff2'),
        url('../assets/fonts/Mark-Heavy.woff') format('woff'),
        url('../assets/fonts/Mark-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}